import { BarChart as KarnottBarChart } from '@karnott/charts';
import React, { useMemo } from 'react';
import { colors } from '@karnott/colors';

const stats = [
  {
    name: '21-01-31T08:34:47+00:00',
    data: [2837],
  },
  {
    name: '21-02-27T08:34:47+00:00',
    data: [1627],
  },
  {
    name: '21-03-31T08:34:47+00:00',
    data: [2328],
  },
  {
    name: '21-04-30T08:34:47+00:00',
    data: [3846],
  },
  {
    name: '21-05-31T08:34:47+00:00',
    data: [7921],
  },
  {
    name: '21-06-30T08:34:47+00:00',
    data: [9058],
  },
  {
    name: '21-07-31T08:34:47+00:00',
    data: [14381],
  },
  {
    name: '21-08-31T08:34:47+00:00',
    data: [9771],
  },
  {
    name: '21-09-30T08:34:47+00:00',
    data: [8836],
  },
  {
    name: '21-10-31T08:34:47+00:00',
    data: [5989],
  },
  {
    name: '21-11-30T08:34:47+00:00',
    data: [4506],
  },
  {
    name: '21-12-31T08:34:47+00:00',
    data: [7725],
  },
  {
    name: '22-01-31T08:34:47+00:00',
    data: [2880],
  },
];

export default function MRRBarChart() {
  const [coef, indexOfMaxValue] = useMemo(() => {
    let value = null,
      indexOfMaxValue;
    stats.forEach((stat, index) => {
      if (!value || stat.data[0] > value) {
        indexOfMaxValue = index;
        value = stat.data[0];
      }
    });
    return [value / 100, indexOfMaxValue];
  }, []);

  const statsAsPercentile = useMemo(() => {
    return stats.map((stat, index) => {
      return {
        ...stat,
        data: indexOfMaxValue !== index ? [stat.data[0] / coef] : stat.data,
      };
    });
  }, [coef, indexOfMaxValue]);

  const dataSeries = useMemo(
    () => ({
      descriptions: [{ label: 'MRR', color: colors('bleen') }],
      series: statsAsPercentile,
    }),
    [statsAsPercentile],
  );

  return (
    <div className={'flex flex-col items-stretch flex-1'}>
      <KarnottBarChart
        withDescription
        dataSeries={dataSeries}
        renderTitle={() => 'MRR signé sur les 12 derniers mois'}
      />
    </div>
  );
}
