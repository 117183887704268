import { PerformanceScoreboard } from '@karnott/charts';
import React, { useMemo } from 'react';
import { colors } from '@karnott/colors';
import { AreaByClusterService } from '../services/dashboard';
import { useSelector } from 'react-redux';

export default function CustomerPerformanceScoreboard() {
  const stats = useSelector(AreaByClusterService.selectors.getSerializedValue);

  const dataSeries = useMemo(
    () => ({
      descriptions: [{ label: 'Surface travaillée (ha)', color: colors('green') }],
      series: stats,
    }),
    [stats],
  );

  return (
    <div className={'flex flex-col items-stretch flex-1'}>
      <PerformanceScoreboard
        renderRecap={() => {
          return <>Surface par client (depuis le début du mois)</>;
        }}
        dataSeries={dataSeries}
      />
    </div>
  );
}
