import { CRUDService } from '@karnott/core-js';
import { API_BASE } from '../../constants';
import round from 'lodash-es/round';

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const HealtStatusService = new CRUDService({
  name: 'healt',
  apiPath: 'dashboard/device_health_stats',
  apiBase: API_BASE,
  authenticated: true,
  prefix: 'HEALTH',
  accessorChain: ['healt'],
});

export const AreaByClusterService = new CRUDService({
  name: 'areaByCluster',
  apiPath: 'dashboard/area_by_cluster_stats',
  apiBase: API_BASE,
  authenticated: true,
  prefix: 'AREA_BY_CLUSTER',
  accessorChain: ['areaByCluster'],
  defaultQuery: {
    from_date: firstDay.toISOString(),
    to_date: lastDay.toISOString(),
    limit: 5,
  },
  serializeValue: (areaByCluster) => {
    return (areaByCluster || []).map((item) => ({
      name: item.name,
      data: [round(item.area, 2)],
    }));
  },
});

export const RingoverService = new CRUDService({
  name: 'ringover',
  apiPath: 'dashboard/ringover',
  apiBase: API_BASE,
  authenticated: true,
  prefix: 'RINGOVER',
  defaultQuery: {
    fromDate: firstDay.toISOString(),
    toDate: lastDay.toISOString(),
  },
  accessorChain: ['ringover'],
  serializeValue: (ringOverStats = {}) => {
    const hours = Math.floor((ringOverStats?.sum_total_duration || 0) / 3600);
    const minutes = Math.floor(((ringOverStats?.sum_total_duration || 0) % 3600) / 60);
    return {
      ...ringOverStats,
      sum_total_duration: `${hours}h${minutes}min`,
    };
  },
});
