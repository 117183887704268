import React from 'react';
import { RingoverService } from '../services/dashboard';
import { useSelector } from 'react-redux';

export default function RingOverStats() {
  const stats = useSelector(RingoverService.selectors.getSerializedValue);

  return (
    <div className="flex flex-col p-4 justify-center items-center h-full w-full">
      <div>
        <b className="text-4xl">{stats?.total_call_count}</b> appels passés
      </div>
      <div>
        <b className="text-4xl">{stats?.sum_total_duration}</b> passées au téléphone
      </div>
      <div className="text-xs">(depuis le début du mois)</div>
    </div>
  );
}
