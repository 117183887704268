import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Button } from '@karnott/buttons';
import AuthenticationService from './services/authentication';
import Dashboard from './Dashboard';

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(AuthenticationService.selectors.hasAccessToken);
  const goToLogin = React.useCallback(() => dispatch(AuthenticationService.actioner.startOAuth2Workflow()), [dispatch]);
  React.useEffect(() => {
    if (!isLoggedIn) {
      goToLogin();
    }
  }, [isLoggedIn, goToLogin]);

  if (isLoggedIn) return <Dashboard />;

  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen">
      <span>Il semblerait que vous ne soyez plus connecté !</span>
      <Button color="orange" title="Me reconnecter" onClick={goToLogin} />
    </div>
  );
}

export default App;
