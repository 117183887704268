import React, { useEffect } from 'react';
import GeojsonHexHeatmap from './components/map/GeojsonHexHeatmap';
import KarnottRatio from './components/KarnottRatio';
import CustomerPerformanceScoreboard from './components/CustomerPerformanceScoreboard';
import { useDispatch, useSelector } from 'react-redux';
import MRRBarChart from './components/MRRBarChart';
import RingOverStats from './components/RingOverStats';
import { initApp } from './services/initialization/actioner';
import BeaconsService from './services/beacons';
import DevicesService from './services/devices';
import geojson from './constants/france.json';

function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  const devices = useSelector(DevicesService.selectors.getList);
  const beacons = useSelector(BeaconsService.selectors.getList);

  const allDevices = React.useMemo(() => (devices && beacons ? devices.concat(beacons) : []), [devices, beacons]);

  return (
    <div className="flex flex-col h-screen w-screen">
      <div className="flex flex-row h-header-height shadow-md bg-white items-center px-8 py-1">
        <img src="./logo.svg" alt="Logo" className="h-8 mr-2" />
      </div>
      <div className="grid xl:grid-cols-4 grid-cols-1 h-full w-full p-4 bg-bleen gap-4 overflow-auto">
        <div className={'grid xl:grid-rows-2 gap-4'}>
          <div className="flex flex-1 shadow-md bg-white rounded grid grid-rows-1 gap-4">
            <RingOverStats />
          </div>
          <div className="flex flex-1 shadow-md bg-white rounded grid grid-rows-1 gap-4">
            <KarnottRatio />
          </div>
        </div>
        <div className={'grid xl:grid-rows-2 gap-4'}>
          <div className="flex flex-1 shadow-md bg-white rounded grid grid-rows-1 gap-4">
            <CustomerPerformanceScoreboard />
          </div>
          <div className="flex flex-1 shadow-md bg-white rounded grid grid-rows-1 gap-4 overflow-hidden">
            <MRRBarChart />
          </div>
        </div>
        <div className="flex flex-1 xl:col-span-2 shadow-md bg-white rounded xl:h-full h-96 gap-4 ">
          <GeojsonHexHeatmap geojson={geojson} devices={allDevices} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
