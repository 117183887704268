import { AuthenticationService } from '@karnott/core-js';
import { OAUTH_CLIENT_ID, OAUTH_REDIRECT_PATH, OAUTH_URL } from '../../constants';

export default new AuthenticationService({
  oauthServerBaseUrl: OAUTH_URL,
  clientId: OAUTH_CLIENT_ID,
  redirectPath: OAUTH_REDIRECT_PATH,
  onAuthExpired: () => {
    window.location = '/';
  },
});
