import * as d3 from 'd3';
import { hexbin as D3HexBin } from 'd3-hexbin';
import { ChartsHooks } from '@karnott/charts';
import React, { useMemo } from 'react';

export function useHexOnGeojson({ svg, hexRadius = 5, geojson, width, height, list, colorScale }) {
  const projection = React.useMemo(
    () => d3.geoConicConformal().fitSize([width, height], geojson),
    [width, height, geojson],
  );
  const points = usePointGrid({ radius: hexRadius, height, width });
  const polygons = usePolygonsForGeojson(geojson, projection);
  const pointsOnPolygon = usePointsInPolygon(points, polygons);
  const dataPoints = useDataPoints(list, projection);
  const dataPointsOnPolygon = usePointsInPolygon(dataPoints, polygons);
  const merge = React.useMemo(
    () => pointsOnPolygon.concat(dataPointsOnPolygon),
    [pointsOnPolygon, dataPointsOnPolygon],
  );

  const [hexPoints, hexbin] = useHexPoints(merge, hexRadius);
  const hexPointsRolledup = useDataPointRollup(hexPoints);

  const hexes = useHexPointsOnSvg(svg, hexPointsRolledup, hexRadius, hexbin, colorScale);
  return hexes;
}

export function useColorScale({ min = 0, max = 100, minColor, maxColor }) {
  return useMemo(() => {
    return d3.scaleLinear().range([minColor, maxColor]).domain([min, max]);
  }, [min, max, minColor, maxColor]);
}

function useDataPointRollup(items) {
  return React.useMemo(() => {
    let maxDatapoints = 0;
    const data = items.splice('');
    data.forEach(function (el) {
      for (var i = el.length - 1; i >= 0; --i) {
        if (el[i].datapoint === 0) {
          el.splice(i, 1);
        }
      }
      var datapoints = 0;
      el.forEach(function (elt, i) {
        datapoints += elt.datapoint;
      });

      el.datapoints = datapoints;
      maxDatapoints = Math.max(maxDatapoints, datapoints);
    });

    return data;
  }, [items]);
}

function usePointGrid({ radius, width, height }) {
  const hexDistance = React.useMemo(() => radius * 1.5, [radius]);
  const cols = React.useMemo(() => width / hexDistance, [hexDistance, width]);
  const rows = React.useMemo(() => Math.floor(height / hexDistance), [height, hexDistance]);

  return React.useMemo(
    () =>
      d3.range(rows * cols).map(function (i) {
        return {
          x: (i % cols) * hexDistance,
          y: Math.floor(i / cols) * hexDistance,
          datapoint: 0,
        };
      }),
    [rows, cols, hexDistance],
  );
}

function usePolygonsForGeojson(geojson, projection) {
  return React.useMemo(() => {
    if (!geojson) return [];
    var polygons = [];
    geojson.features.forEach(function (f) {
      if (f.geometry.type === 'Polygon') {
        var featurePolygon = [];
        f.geometry.coordinates[0].forEach(function (c) {
          featurePolygon.push(projection(c));
        });
        polygons.push(featurePolygon);
      } else {
        // type = MultiPolygon
        f.geometry.coordinates.forEach(function (p) {
          var featurePolygon = [];
          p[0].forEach(function (c) {
            featurePolygon.push(projection(c));
          });
          polygons.push(featurePolygon);
        });
      }
    });
    return polygons;
  }, [geojson, projection]);
}

function usePointsInPolygon(points, polygons) {
  return React.useMemo(() => {
    var pointsInPolygon = [];

    points.forEach(function (point) {
      var inPolygon = false;
      for (var i = 0; !inPolygon && i < polygons.length; i++) {
        if (d3.polygonContains(polygons[i], [point.x, point.y])) {
          inPolygon = true;
        }
      }

      if (inPolygon) {
        pointsInPolygon.push(point);
      }
    });
    return pointsInPolygon;
  }, [points, polygons]);
}

function useDataPoints(list, projection) {
  return React.useMemo(
    () =>
      list.map(({ location_latitude, location_longitude }) => {
        const coords = projection([location_longitude, location_latitude]);
        return {
          x: coords[0],
          y: coords[1],
          datapoint: 1,
          name: '',
        };
      }),
    [projection, list],
  );
}

function useHexPoints(points, hexRadius) {
  const hexbin = React.useMemo(() => {
    return D3HexBin()
      .radius(hexRadius)
      .x(function (d) {
        return d.x;
      })
      .y(function (d) {
        return d.y;
      });
  }, [hexRadius]);

  const hexPoints = React.useMemo(() => hexbin(points), [hexbin, points]);
  return [hexPoints, hexbin];
}

function useHexPointsOnSvg(svg, hexpoints, hexRadius, hexbin, colorScale, transitionDuration = 3000) {
  const [group] = ChartsHooks.useSvgElement({ type: 'g', parent: svg });
  ChartsHooks.useDropShadowOnSvgDefs({});
  const styles = React.useMemo(
    () => [
      { key: 'stroke', value: '#F7F7F7' },
      { key: 'stroke-width', value: '0px' },
      {
        key: 'fill',
        value: (d) => colorScale(d.datapoints),
      },
    ],
    [colorScale],
  );

  const attributes = React.useMemo(
    () => [
      { key: 'class', value: 'hex' },
      { key: 'transform', value: (d) => 'translate(' + (hexRadius + d.x) + ', ' + d.y + ')' },
      { key: 'd', value: (d) => hexbin.hexagon() },
    ],
    [hexbin, hexRadius],
  );

  const [hexes] = ChartsHooks.useDataDrivenSvgElement({
    type: 'path',
    parent: group,
    data: hexpoints,
    styles,
    attributes,
    duration: transitionDuration,
  });

  return hexes;
}
