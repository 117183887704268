import { CircularChart } from '@karnott/charts';
import React, { useCallback, useMemo } from 'react';
import { colors } from '@karnott/colors';
import { HealtStatusService } from '../services/dashboard';
import { useSelector } from 'react-redux';

const descriptions = [
  { label: 'Karnott UP', color: colors('green') },
  { label: 'Karnott DOWN', color: colors('orange') },
  { label: 'Karnott CRITICAL', color: colors('red') },
];

export default function KarnottRatio() {
  const stats = useSelector(HealtStatusService.selectors.getSerializedValue);

  const renderTitle = useCallback(() => {
    return <p style={{ margin: 0 }}>État des Karnott</p>;
  }, []);

  const dataSerie = useMemo(
    () => ({
      data: [stats?.UP, stats?.DOWN, stats?.CRITICAL],
    }),
    [stats],
  );
  if (!stats) return null;
  return (
    <div className="flex justify-center stretch w-full">
      <CircularChart renderTitle={renderTitle} dataSerie={dataSerie} descriptions={descriptions} withTooltip />
    </div>
  );
}
