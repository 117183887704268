import ImplementsService from '../implements';
import BeaconsService from '../beacons';
import DevicesService from '../devices';
import { AreaByClusterService, HealtStatusService, RingoverService } from '../dashboard';

export function initApp() {
  return (dispatch) => {
    return Promise.all([
      dispatch(ImplementsService.actioner.fetchList({}, { status: 'IN_USE', is_demo: false })),
      dispatch(BeaconsService.actioner.fetchList({}, { status: 'IN_USE', is_demo: false })),
      dispatch(DevicesService.actioner.fetchList({}, { status: 'IN_USE', is_demo: false })),
      dispatch(HealtStatusService.actioner.read()),
      dispatch(RingoverService.actioner.read()),
      dispatch(AreaByClusterService.actioner.read()),
    ]);
  };
}
