import React, { useMemo } from 'react';
import { ChartsHooks } from '@karnott/charts';
import { useHexOnGeojson, useColorScale } from './hexmap';
import { colors, rgbaColors } from '@karnott/colors';

export default function GeojsonHexHeatmap({ hexRadius = 4, devices = [], geojson }) {
  const chart = React.useRef(null);

  const { attributes: chartAttributes, styles: chartStyles } = useMemo(
    () => ({
      attributes: [],
      styles: [],
    }),
    [],
  );

  const [svg, width, height] = ChartsHooks.useSvg(chart, chartAttributes, chartStyles);
  const colorScale = useColorScale({
    minColor: rgbaColors('bleen', 'default', 0.1),
    maxColor: colors('orange'),
    max: 5,
    min: 0,
  });
  useHexOnGeojson({ svg, geojson, width, height, list: devices, colorScale, hexRadius });

  return (
    <div className={'flex w-full py-4'}>
      <div ref={chart} className={'flex relative flex-1'} />
    </div>
  );
}
