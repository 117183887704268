import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import AuthenticationService from './authentication';
import PlotsService from './plots';
import ImplementsService from './implements';
import BeaconsService from './beacons';
import DevicesService from './devices';
import { AreaByClusterService, HealtStatusService, RingoverService } from './dashboard';

const store = createStore(
  combineReducers({
    [AuthenticationService.name]: AuthenticationService.reducer.reduce.bind(AuthenticationService.reducer),
    [PlotsService.name]: PlotsService.reducer.reduce.bind(PlotsService.reducer),
    [ImplementsService.name]: ImplementsService.reducer.reduce.bind(ImplementsService.reducer),
    [BeaconsService.name]: BeaconsService.reducer.reduce.bind(BeaconsService.reducer),
    [DevicesService.name]: DevicesService.reducer.reduce.bind(DevicesService.reducer),
    [AreaByClusterService.name]: AreaByClusterService.reducer.reduce.bind(AreaByClusterService.reducer),
    [HealtStatusService.name]: HealtStatusService.reducer.reduce.bind(HealtStatusService.reducer),
    [RingoverService.name]: RingoverService.reducer.reduce.bind(RingoverService.reducer),
  }),
  {},
  compose(
    applyMiddleware(thunkMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ),
);

export default store;
